import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get(`/splashes`);
  }
  create(data) {
    return axios.post("/splashes", data);
  }
  update(id, data) {
    return axios.put(`/splashes`, data);
  }
  delete(id) {
    return axios.delete(`/splashes?id=${id}`);
  }
 
}
export default new DataService();